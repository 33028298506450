import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { LoginGuard } from '../guards/login.guard';
import { LoggedInGuard } from '@app/guards/logged-in.guard';
import { MaintenanceComponent } from '@app/pages/maintenance/maintenance.component';
import { defaultRoute } from '@app/modules/system-config/system-config';
import { CommunityPreviewComponent } from '@app/pages/community-preview/community-preview.component';
import { UpdateInfoGuard } from '@app/guards/update-info.guard';

const routes: Routes = [
  //
  // nivel 0
  {
    path: 'login',
    canActivate: [LoggedInGuard],
    loadChildren: () =>
      import('@app/pages/authentication/pages/login/login.module').then(
        m => m.LoginModule
      ),
  },
  {
    path: 'update-info',
    canActivate: [AuthGuard, UpdateInfoGuard],
    loadChildren: () =>
      import(
        '@app/pages/authentication/pages/update-information/update-information.module'
      ).then(m => m.UpdateInformationModule),
  },
  {
    path: 'agreements',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@app/pages/authentication/pages/contracts/contracts.module').then(
        m => m.ContractsModule
      ),
  },
  {
    path: 'welcome',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@app/pages/authentication/pages/welcome/welcome.module').then(
        m => m.WelcomeModule
      ),
  },
  {
    path: 'verification-code',
    loadChildren: () =>
      import(
        '@app/pages/authentication/pages/verification-code/verification-code.module'
      ).then(m => m.VerificationCodeModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import(
        '@app/pages/authentication/pages/forgot-password/forgot-password.module'
      ).then(m => m.ForgotPasswordModule),
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  {
    path: 'community-preview/:id',
    loadChildren: () =>
      import('@app/pages/community-preview/community-preview.module').then(
        m => m.CommunityPreviewModule
      ),
  },
  // nivel 1
  // app
  {
    path: '',
    canActivate: [LoginGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@app/app-routing.module').then(m => m.AppRoutingModule),
      },
    ],
  },
  { path: '', redirectTo: defaultRoute, pathMatch: 'full' },
  { path: '**', redirectTo: defaultRoute, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class RootRoutingModule {}