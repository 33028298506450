<cdk-select
  [value]="currentRole"
  *ngIf="!isMobile"
  [options]="roles"
  [multi]="false"
  [showSelected]="false"
  placeholder="Click to switch profile"
  [placeholderSize]="12"
  placeholderColor="#7DB0E8"
  icon="unfold_more"
  [optionsCustomClass]="'container-rol'"
  class="bg-blue-600 rol-select"
  style="min-width: 208px"
  direction="cr">
  <ng-template #itemTmpl let-item>
    <app-menu-item
      [id]="formatID(item)"
      [defaultOption]="defaultRole?.id"
      [currentOption]="currentRole?.id"
      (onPressIconEmit)="setFav(item)"
      (onPressItemEmit)="changeRole(item)"
      [isRoleItem]="true"
      variant="fav-menu"
      [item]="item">
    </app-menu-item>
  </ng-template>
</cdk-select>

<switch-control-mobile *ngIf="isMobile">
  <app-menu-item
    *ngFor="let item of roles"
    [id]="'RESPONSIVE_' + formatID(item)"
    [defaultOption]="defaultRole?.id"
    [currentOption]="currentRole?.id"
    (onPressIconEmit)="setFav(item)"
    (onPressItemEmit)="changeRole(item)"
    [isRoleItem]="true"
    variant="fav-menu"
    [item]="item">
  </app-menu-item>
</switch-control-mobile>
