import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { map, Observable } from "rxjs";
import { UsersService } from "@app/services/users/users.service";

@Injectable({
  providedIn: "root",
})
export class UpdateInfoGuard implements CanActivate {
  constructor(private userService: UsersService, private router: Router) {}

  canActivate(): Observable<boolean> | boolean {
    if (this.userService.getToken()) {
      return this.userService.fetchUser().pipe(
        map(data => {
          const { user } = data;

          if (user.update_data) {
            this.router.navigate(["/home"]);
            return false;
          }

          return true;
        })
      );
    }

    this.router.navigate(["/login"]);
    return false;
  }
}
