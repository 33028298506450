import { Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({
  selector: "[appButtonTabGroup]",
})
export class ButtonTabGroupDirective implements OnInit {
  @Input() buttonPosition: "left" | "right" = "right";
  @Input() classButtons: string[] = [];

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.changeButtonElement();
  }

  /**
   * Permite cambiar el estilo y posición de los botones del TabGroup
   * @return{void}
   */
  changeButtonElement(): void {
    const tabHeader = this.getElementBySelector("mat-tab-header");
    const buttonPaginatorBefore = this.getElementBySelector(
      "button.mat-tab-header-pagination-before"
    );
    const buttonPaginatorAfter = this.getElementBySelector(
      "button.mat-tab-header-pagination-after"
    );
    const iconButtons = this.elementRef.nativeElement.querySelectorAll(
      ".mat-tab-header-pagination-chevron"
    );

    tabHeader.removeChild(buttonPaginatorBefore);
    tabHeader.removeChild(buttonPaginatorAfter);

    const buttonsTabGroup = [buttonPaginatorBefore, buttonPaginatorAfter];

    this.addClassElements(buttonsTabGroup, "d-flex");
    this.addClassIconButtons(iconButtons);

    this.addButtons(tabHeader, buttonsTabGroup);
  }

  /**
   * Obtiene los elementos dentro del TabGroup
   * @param{string} selector Identificador que permitirá buscar en el contenedor de la directiva
   * @return{void}
   */
  getElementBySelector(selector: string): HTMLElement {
    return this.elementRef.nativeElement.querySelector(selector);
  }

  /**
   * Permite agregar los botones para poder reposicionarlos en el contenedor
   * @param{HTMLElement} container Contenedor donde se van a agregar los botones
   * @param{Node[]} nodes Botones que van a ser reposicionados
   * @return{void}
   */
  addButtons(container: HTMLElement, nodes: Node[]): void {
    if (this.buttonPosition === "left") {
      nodes.reverse().forEach(node => {
        container.prepend(node);
      });
    }

    if (this.buttonPosition === "right") {
      nodes.forEach(node => {
        container.append(node);
      });
    }
  }

  /**
   * Agrega clases a un grupo de elementos.
   * @param{HTMLElement} buttons Botones que se van a agregar las clases
   * @param{string} classButtons Nombre de la clase que se va a asignar
   * @return{void}
   */
  addClassElements(buttons: HTMLElement[], classButtons: string): void {
    buttons.forEach(button => {
      button.classList.add(classButtons);
    });
  }

  /**
   * Agrega clase a los iconos de botones del TabGroup
   * @param{HTMLElement[]} elementsRef Grupo de elementos a los que se va a agregar la clase
   * return{void}
   */
  addClassIconButtons(elementsRef: HTMLElement[]): void {
    this.classButtons.forEach(classButton => {
      this.addClassElements(elementsRef, classButton);
    });
  }
}
