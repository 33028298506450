import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Observable } from "rxjs";
import { IDataTableHttpResponse, IParams } from "@app/shared";
import { UiService } from "@app/services/cdk/ui.service";

@Directive({
  selector: "[appInfiniteScroll]",
})
export class InfiniteScrollDirective implements OnInit, OnChanges, OnDestroy {
  isLoading = false;
  hasMoreData = true;
  dataReport: unknown[] = [];
  /**
   * Umbral para disparar la carga (donde 1 equivale a un item).
   * Si no se especifica, se calculará automáticamente en función del tamaño del contenedor.
   */
  @Input() threshold = 1;
  @Input() loadData: (
    filterScroll: IParams
  ) => Observable<IDataTableHttpResponse>;
  @Input() filterScroll: IParams = {
    page: 1,
    per_page: 15,
    filters: undefined,
  };
  @Output() dataResult = new EventEmitter<unknown[]>();
  @Output() loading = new EventEmitter<boolean>();
  private observer: MutationObserver;
  private _elementHeightMeasured = false;
  private _firstElementHeight: number;

  constructor(private _el: ElementRef, private _uiService: UiService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const {
      filterScroll: {
        currentValue: { filters: currentFilters } = { filters: undefined },
        previousValue: { filters: previousFilters } = { filters: undefined },
      } = {},
    } = changes ?? {};

    this.evaluateFiltersValue(currentFilters, previousFilters);
  }

  ngOnInit(): void {
    this.callAPI(this.filterScroll);
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  @HostListener("scroll") onScroll(): void {
    const container = this._el.nativeElement;
    const scrollPosition = container.scrollTop + container.clientHeight;
    const triggerPoint =
      container.scrollHeight - this._firstElementHeight * this.threshold;

    if (this.isLoading || !this.hasMoreData || !this.loadData) return;

    if (scrollPosition >= triggerPoint && !this.isLoading && this.hasMoreData) {
      this.callAPI(this.filterScroll);
    }
  }

  callAPI(filterScroll: IParams): void {
    this.handleLoading(true);
    try {
      this.loadData(filterScroll).subscribe({
        next: ({ data, total }) => {
          this.dataReport = [...this.dataReport, ...data];

          this.dataResult.emit(this.dataReport);
          this.hasMoreData = this.dataReport.length < total;

          this.filterScroll.page++;

          !this._elementHeightMeasured && this.observeChangesDom();
        },
        error: err => {
          this.handleErrorAPIFunction(err);
          this.handleLoading(false);
        },
        complete: () => {
          this.handleLoading(false);
        },
      });
    } catch (err) {
      this.handleErrorAPIFunction(err);
    }
  }

  handleLoading(isLoading: boolean) {
    this.isLoading = isLoading;
    this.loading.emit(isLoading);
  }

  evaluateFiltersValue(currentValue: IParams, previousValue: IParams) {
    if (JSON.stringify(currentValue) != JSON.stringify(previousValue)) {
      this.dataReport = [];
      this.filterScroll.page = 1;
      this.hasMoreData = true;

      this.dataResult.emit(this.dataReport);
      this.callAPI(this.filterScroll);
    }
  }

  handleErrorAPIFunction(err: unknown) {
    this._uiService.showAlert("An error has occurred.", "error");
    console.error(err);
    this.hasMoreData = false;
    this.handleLoading(false);
  }

  observeChangesDom() {
    this.observer = new MutationObserver(() => {
      const firstElement = this._el.nativeElement.firstElementChild;
      if (firstElement) {
        this._firstElementHeight = firstElement.offsetHeight;

        this._elementHeightMeasured = true;
        this.observer.disconnect();
      }
    });

    this.observer.observe(this._el.nativeElement, {
      childList: true,
      subtree: false,
    });
  }
}
