import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpService} from '../http/http.service';
import * as qs from 'qs';
import { LocalStorageService } from '../storage/local-storage.service';
import { LocalStorageKeys } from '@app/enums/localstorage';

@Injectable({
  providedIn: 'root',
})
export class PowerbiService {
  localStorageKeys = LocalStorageKeys;

  constructor(protected http: HttpService, private _localStorage: LocalStorageService) { }

  public get(role: string, name: string): Observable<any> {
    const url = `powerbi/token/${role}/${name}`;

    return this.http.getModule<any>(url).pipe(map((res) => res.data));
  }

  public getDashboards(params: {role: string; dashboards: string[]}) {
    const url = `powerbi/tokens?` + qs.stringify(params);

    return this.http.getModule(url).pipe(map((res) => res.data));
  }

  public getClosedDashboardPopups(): string[] {
    const skipPopups = this._localStorage.getItem(
      this.localStorageKeys.SKIP_DASHBOARD_POPUPS
    );
    return skipPopups ? JSON.parse(skipPopups) : [];
  }

  public addClosedDashboardPopup(route: string) {
    const skipPopups = this.getClosedDashboardPopups();
    skipPopups.push(route);
    this._localStorage.setItem(
      this.localStorageKeys.SKIP_DASHBOARD_POPUPS,
      JSON.stringify(skipPopups)
    );
  }
}
