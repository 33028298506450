<div
  *ngIf="variant === 'fav-menu'"
  class="container-module-menu container-module-menu__variant-fav-menu d-flex flex-row justify-content-start align-items-center w-100 py-25 gap-3 cursor-pointer"
  [class.container-module-menu__variant-fav-menu__active]="
    currentOption === item?.id
  "
  [class.container-module-menu__variant-fav-menu__default]="
    defaultOption === item?.id
  "
  (click)="onPressItem()">
  <span
    cdkIcon="rounded"
    (click)="$event.stopPropagation(); onPressIcon()"
    [fill]="isFavorite(item)"
    >star</span
  >
  <ng-container *ngIf="!isRoleItem; else isItemRole">
    <span class="w-100 text-truncate menu-item-label">{{ item.name }}</span>
  </ng-container>
  <ng-template #isItemRole>
    <span class="w-100 text-truncate menu-item-label">{{ item.title }}</span>
  </ng-template>
</div>

<div
  *ngIf="variant === 'simple-menu'"
  class="container-module-menu container-module-menu__variant-simple-menu d-flex flex-row justify-content-start align-items-center w-100 py-25 gap-3 cursor-pointer"
  [routerLinkActive]="isLinkRouter ? 'active' : ''"
  [id]="customId ?? ''"
  [routerLink]="isLinkRouter ? [item.route] : null"
  (click)="onPressItem()">
  <span
    *ngIf="!item.isImg"
    [style.color]="item.icon_color"
    [styleClass]="!item.icon_color && getModuleIcoStatus(item.name)"
    cdkIcon="outlined"
    [fill]="iconFilled"
    (click)="$event.stopPropagation(); onPressIcon()"
    >{{ item.icon }}</span
  >
  <img
    *ngIf="item.isImg"
    width="25"
    height="25"
    [src]="item.icon"
    alt="flag-options" />
  <span class="w-100 text-truncate menu-item-label">{{ item.name }}</span>
</div>

<div
  *ngIf="variant === 'image-menu'"
  class="container-module-menu container-module-menu__variant-image-menu d-flex flex-row justify-content-start align-items-center w-100 gap-3 cursor-pointer"
  [routerLinkActive]="isLinkRouter ? 'active' : ''"
  [routerLink]="isLinkRouter ? [item.route] : null"
  [class.container-module-menu__variant-image-menu__active]="
    currentOption === item?.id || currentOption === item?.name
  "
  (click)="onPressItem()">
  <span
    *ngIf="!item.isImg"
    [styleClass]="getModuleIcoStatusColor(item.name)"
    cdkIcon="outlined"
    [fill]="iconFilled"
    (click)="$event.stopPropagation(); onPressIcon()"
    >{{ item.icon }}</span
  >
  <img
    *ngIf="item.isImg"
    width="22"
    height="22"
    [src]="item.icon"
    alt="flag-options" />
  <span class="w-100 text-truncate menu-item-label">{{ item.name }}</span>
</div>
