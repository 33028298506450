import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { switchMap } from "rxjs/operators";
import { UiService } from "@app/services/cdk/ui.service";

import { humanizeSeconds } from "@app/utils/humanizeSeconds";
import { AuthService } from "@app/services/auth/auth-service.service";
import { UsersService } from "@app/services/users/users.service";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { environment } from "src/environments/environment";
import { of } from "rxjs";

@Component({
  selector: "app-verification-code",
  templateUrl: "./verification-code.component.html",
  styleUrls: ["./verification-code.component.scss"],
})
export class VerificationCodeComponent implements OnInit, OnDestroy {
  error = {
    status: false,
    message: "",
  };

  message: string;
  storeUser: string | boolean;
  authToken: string;
  timeout = 0;
  displayTimeout: string;

  codeForm: FormGroup = this._fb.group({
    "code-1": [null, [Validators.required]],
    "code-2": [null, [Validators.required]],
    "code-3": [null, [Validators.required]],
    "code-4": [null, [Validators.required]],
    "code-5": [null, [Validators.required]],
    "code-6": [null, [Validators.required]],
  });

  loading = false;
  trustDevice = false;
  captchaElement: HTMLElement;

  @ViewChild("code1") initialDigit: ElementRef<HTMLInputElement>;
  @ViewChildren("code") inputsCodeRefs: QueryList<ElementRef>;

  constructor(
    private _fb: FormBuilder,
    public router: Router,
    private authService: AuthService,
    private userService: UsersService,
    private activatedRoute: ActivatedRoute,
    private ui: UiService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.storeUser =
      this.activatedRoute.snapshot.queryParamMap.get("remember") || false;

    this.authToken =
      this.activatedRoute.snapshot.queryParamMap.get("token") || undefined;
  }

  ngOnInit(): void {
    this.sendVerificationCode();

    this.captchaElement = document.getElementsByClassName(
      "grecaptcha-badge"
    )[0] as HTMLElement;
    if (this.captchaElement) this.captchaElement.style.visibility = "visible";
  }

  ngOnDestroy(): void {
    this.codeForm.reset();
    if (this.captchaElement) this.captchaElement.style.visibility = "hidden";
  }

  sendVerificationCode() {
    if (this.timeout !== 0) return;

    if (!this.authService.isLogin) {
      this.router.navigate(["/"]);
      return;
    }

    this.loading = true;
    this.ui.startLoading();

    this.timeout = 60000;
    this.startTimeout();

    this.authService.sendVerificationCode(this.authToken).subscribe({
      error: error => {
        this.loading = false;
        this.ui.stopLoading();
        this.ui.showAlert(error);
      },
      complete: () => {
        this.loading = false;
        this.ui.stopLoading();
      },
    });
  }

  submit(): void {
    this.message = null;
    this.loading = true;

    const recaptchaRequest = environment.production
      ? this.recaptchaV3Service.execute("login")
      : of(undefined);

    this.ui.startLoading();
    recaptchaRequest
      .pipe(
        switchMap(token => {
          return this.authService.checkVerificationCode(
            Object.values(this.codeForm.value).join("").toUpperCase(),
            this.trustDevice,
            this.authToken,
            token
          );
        })
      )
      .subscribe({
        next: ({ data }) => {
          this.userService.loadLoginData(data, this.storeUser);
          this.router.navigate(["/home"]);
        },
        error: error => {
          this.codeForm.reset();
          this.initialDigit?.nativeElement?.focus();
          this.message = "Unexpected Error";

          if (error.status === 422) {
            const expired_code = error.error?.data?.expired_code;
            const g_recaptcha_response =
              error.error?.data?.g_recaptcha_response;
            const attempts = error.error?.data?.attempts_left
              ? error.error?.data?.attempts_left[0]
              : undefined;

            if (g_recaptcha_response || (attempts && attempts.length > 1)) {
              this.authService.logout(false);
              this.ui.stopLoading();
              this.router.navigateByUrl("login");
              return;
            }

            this.message = attempts
              ? (expired_code ?? `Invalid code, ${attempts} attempts left`)
              : "Invalid Code";
          }

          this.loading = false;
          this.ui.stopLoading();
          this.ui.closeAlert();
        },
        complete: () => {
          this.loading = false;
          this.ui.stopLoading();
        },
      });
  }

  startTimeout() {
    const interval = setInterval(() => {
      this.timeout -= 1000;
      this.displayTimeout = humanizeSeconds(
        Math.round(this.timeout / 1000),
        "mm:ss"
      );
      if (this.timeout === 0) clearInterval(interval);
    }, 1000);
    this.displayTimeout = "";
  }

  nextFocus(index: number, value: string) {
    const codeRefs = this.inputsCodeRefs.toArray();
    if (index < codeRefs.length) {
      if (value) codeRefs[index].nativeElement.focus();
    }
  }

  pasteVerificationCode(event: ClipboardEvent) {
    const clipboardText = event.clipboardData.getData("text");
    for (let i = 0; i < clipboardText.length; ++i) {
      const data = clipboardText[i];
      if (clipboardText.length === 6) {
        this.codeForm.get(`code-${i + 1}`).patchValue(data);
      }
    }

    const codeRefs = this.inputsCodeRefs.toArray();
    codeRefs[codeRefs.length - 1].nativeElement.focus();
  }

  onKeyPress(index: number, event: any) {
    const codeRefs = this.inputsCodeRefs.toArray();

    switch (event.key) {
    case "Backspace":
      if (!this.codeForm.get(`code-${index}`).value && index > 1) {
        setTimeout(() => {
          codeRefs[index - 2].nativeElement.focus();
        }, 100);
      }

      break;
    case "ArrowLeft":
      if (index > 1) {
        setTimeout(() => {
          codeRefs[index - 2].nativeElement.focus();
        }, 100);
      }

      break;
    case "ArrowRight":
      if (index < codeRefs.length) {
        setTimeout(() => {
          codeRefs[index].nativeElement.focus();
        }, 100);
      }

      break;
    default:
      break;
    }
  }

  transformToUppercase(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = inputElement.value.toUpperCase();
  }
}
