import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DateInputDirective } from "./date-input.directive";
import { DataTableTemplateDirective } from "./data-table/data-table-template.directive";
import { ClickStopPropagation } from "./stop-propagation/stop-propagation.directive";
import { ScrollDirective } from "./scroll/scroll.directive";
import { CrashImageDirective } from "./crash-image/crash-image.directive";
import { DisableDirective } from "./disable/disable.directive";
import { HideDirective } from "./hide-directive/hide.directive";
import { TabPanelTemplateDirective } from "./tab-panel/tab-panel-template.directive";
import { ButtonTabGroupDirective } from "./policy360/button-tab-group.directive";
import { InfiniteScrollDirective } from "@app/directives/policy360/infiniteScroll.directive";

@NgModule({
  declarations: [
    DateInputDirective,
    DataTableTemplateDirective,
    ClickStopPropagation,
    ScrollDirective,
    CrashImageDirective,
    DisableDirective,
    HideDirective,
    TabPanelTemplateDirective,
    ButtonTabGroupDirective,
    InfiniteScrollDirective,
  ],
  imports: [CommonModule],
  exports: [
    DateInputDirective,
    DataTableTemplateDirective,
    ClickStopPropagation,
    ScrollDirective,
    CrashImageDirective,
    DisableDirective,
    HideDirective,
    TabPanelTemplateDirective,
    ButtonTabGroupDirective,
    InfiniteScrollDirective,
  ],
})
export class DirectivesModule {}
