<form autocomplete="off">
  <div class="input-group" #searchInput>
    <input
      (input)="filter($event)"
      (focus)="showPopup()"
      (focusout)="hidePopup()"
      type="search"
      autocomplete="new-password"
      name="input"
      id="main-buscador-navbar"
      placeholder="Search, statements, portfolio, recon…"
      class="form-control text-light border border-0 bg-gray-800 shadow-none pw-field" />
    <span class="input-group-text border border-0 bg-gray-800 text-light">
      <span cdkIcon="outlined" styleClass="icon-color"> search </span>
    </span>
  </div>
</form>

<div
  class="popup position-absolute bg-light p-2 mt-1 rounded"
  [style.width.px]="offsetWidth"
  [style.height.rem]="items && items.length > 0 === true ? '10rem' : '4rem'"
  *ngIf="popupVisible">
  <ul>
    <ng-container *ngFor="let item of items; let i = index">
      <li
        class="recent-item"
        *ngIf="item.recent"
        (click)="navigate(item)"
        [class.selected]="i === indexSelected">
        <div class="d-flex">
          <span
            (click)="removeRecentSearch(item, $event)"
            style="color: #919398"
            cdkIcon="outlined"
            >replay</span
          >&nbsp; &nbsp;
          <div style="color: #174b84" [innerHTML]="item?.name"></div>
        </div>
        <span
          (click)="removeRecentSearch(item, $event)"
          style="background: #abadb1; color: #fff; border-radius: 50%"
          cdkIcon="outlined"
          >close</span
        >
      </li>

      <li
        class="search-item"
        *ngIf="!item.recent"
        (click)="navigate(item)"
        [class.selected]="i === indexSelected">
        <div [innerHTML]="item?._name"></div>
      </li>
    </ng-container>

    <ng-container *ngIf="items && items.length <= 0">
      <p *ngIf="!searchText" class="text-center placeholder-text m-2">
        Type something to begin<br />your search
      </p>

      <p *ngIf="searchText" class="text-center placeholder-text m-2">
        We couldn't find any results<br />for your search
      </p>
    </ng-container>
  </ul>
</div>
